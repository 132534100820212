import React from 'react';
import HostingFeaturesItem from './HostingFeaturesItem';

const HostingFeaturesTwo =()=>{
    return(
        <section className="hosting_features_area">
            <div className="container">
                <HostingFeaturesItem rowClass="flex-row-reverse" hImage="business2.png" hTitle="Video Editing, Graphic Design, Live Broadcasting" hPragraph="Running a project but nobody can assist in media and technical job? We can help you!" 
                icon="icon2.png" icon2="icon8.png" text="Video Editing & Graphic Designing" text2="Live Broadcasting" tdescription="Highlights, Montage, Corporate, Promotional Videos. Poster, Banner, Stickers, Flyers Design." tdescription2="Facebook and YouTube Live Broadcasting."/>
            </div>
        </section>
    )
}

export default HostingFeaturesTwo;