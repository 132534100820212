import React, {Component} from 'react';
import Isotope from 'isotope-layout/js/isotope';
import ImagesLoaded from 'imagesloaded/imagesloaded';
import Portfolio2griditem from './Portfolio2griditem';
import Sectitle from '../Title/Sectitle';
import Portfolio2gridvideo from './Portfolio2gridvideo';

class Portfoliofull4col extends Component{
    state = {
        activeItem: '*',
    }
    
    componentDidMount() {
        var imgLoad = new ImagesLoaded('#work-portfolio');
    
        imgLoad.on('progress', function(instance, image) {
            this.iso = new Isotope('#work-portfolio', {
                itemSelector: '.portfolio_item',
                layoutMode: "masonry"
            });
        }); 
        
    }
    onFilterChange = (newFilter) => {
        
        this.setState({activeItem: newFilter});
        if (this.iso === undefined) {
            this.iso = new Isotope('#work-portfolio', {
            itemSelector: '.portfolio_item',
            layoutMode: "masonry"
            });
        }
    
    // this.iso.arrange({ filter: newFilter });
        
      if(newFilter === '*') {
        this.iso.arrange({ filter: `*` });
      } else {
        this.iso.arrange({ filter: `.${newFilter}` });
      }
    }

    onActive = v => v === this.state.activeItem ? 'active' : '';

    render(){
        
        // { url:"", title:"", tag1:"Video", tag2:"", mtitle: ""},
        
        const resourcesVideo = [
            { url:"https://youtu.be/CT7_1CMwlNE", title:"Commercial Advertisement", tag1:"Video", tag2:"Promotion", mtitle: "OishiiTakoyaki3"},
            { url:"https://youtu.be/kF3KXfJFWFQ", title:"Event Highlights", tag1:"Video", tag2:"Compilation", mtitle: "MAP IMPRESS"},
            { url:"https://youtu.be/0za43QcXF1I", title:"Commercial Advertisement", tag1:"Video", tag2:"Promotion", mtitle: "OishiiTakoyaki2"},
            { url:"https://youtu.be/ZnNu1ZO3LzA", title:"Commercial Advertisement", tag1:"Video", tag2:"Promotion", mtitle: "OishiiTakoyaki1"},
            { url:"https://youtu.be/HHpHiPe2QF0", title:"Personal Vlog", tag1:"Video", tag2:"Vlog", mtitle: "BondingWithNature"},
            { url:"https://www.youtube.com/watch?v=ErkcaRrKD-g", title:"Event Highlights", tag1:"Video", tag2:"3D - Compilation", mtitle: "SambutanKemerdekaan"},
            { url:"https://www.youtube.com/watch?v=Z5L0FYT_s_Q", title:"Event Highlights", tag1:"Video", tag2:"Vlog", mtitle: "IkhwahDayOut"},
            { url:"https://www.youtube.com/watch?v=bsIDzphDKgk", title:"Event Highlights", tag1:"Video", tag2:"Vlog", mtitle:"KorbanKasih" },
            { url:"https://www.youtube.com/watch?v=zCfczi1egUs", title:"Event Highlights", tag1:"Video", tag2:"Vlog", mtitle:"ZiarahPrihatinAsnaf" },
            { url:"https://www.youtube.com/watch?v=JDEQBfLZ2oI", title:"Informative Video", tag1:"Video", tag2:"Literature", mtitle:"KuOn9" },
            { url:"https://www.youtube.com/watch?v=FsZ4rAFhf8o", title:"Event Highlights", tag1:"Video", tag2:"Vlog", mtitle:"ZiarahPrihatinRam2020" },
            { url:"https://www.youtube.com/watch?v=5U-Oc7F4CR0", title:"Informative Video", tag1:"Video", tag2:"Literature", mtitle:"KuOn8" },
            { url:"https://www.youtube.com/watch?v=EQaDK-dBuj8", title:"Informative Video", tag1:"Video", tag2:"Literature", mtitle:"KuOn7" },
            { url:"https://www.youtube.com/watch?v=Xutl0DmQB0o", title:"Informative Video", tag1:"Video", tag2:"Literature", mtitle:"KuOn6" },
            { url:"https://www.youtube.com/watch?v=lvg_YbLlfr8", title:"Informative Video", tag1:"Video", tag2:"Literature", mtitle:"KuOn5" },
            { url:"https://www.youtube.com/watch?v=zB4iPcbOZPM", title:"Informative Video", tag1:"Video", tag2:"Literature", mtitle:"KuOn4" },
            { url:"https://www.youtube.com/watch?v=GEoSkVSU6EI", title:"Informative Video", tag1:"Video", tag2:"Literature", mtitle:"KuOn3" },
            { url:"https://www.youtube.com/watch?v=r8iwmnw-sKc", title:"Informative Video", tag1:"Video", tag2:"Literature", mtitle:"KuOn2" },
            { url:"https://www.youtube.com/watch?v=LqxAKQnfOuc", title:"Informative Video", tag1:"Video", tag2:"Literature", mtitle:"KuOn1" },
            { url:"https://www.youtube.com/watch?v=M_J4Qjj73Gc", title:"Narrative Animation", tag1:"Video", tag2:"2D", mtitle:"FastAThon" },
            { url:"https://www.youtube.com/watch?v=HYxP_hI-gsM", title:"Tutorial Video", tag1:"Video", tag2:"2D", mtitle:"Sakeenah" },
            { url:"https://www.youtube.com/watch?v=kSX3D4vCroM", title:"Event Highlights", tag1:"Video", tag2:"Vlog", mtitle:"PerhimpunanIKRAM2019" },
            { url:"https://www.youtube.com/watch?v=zHNQCUnqO5A", title:"Event Highlights", tag1:"Video", tag2:"Vlog", mtitle:"SNTP"},
            { url:"https://www.youtube.com/watch?v=vA13BkHyfe0", title:"Event Highlights", tag1:"Video", tag2:"Vlog", mtitle:"Jaulah Muharrik" },
            { url:"https://www.youtube.com/watch?v=bX7qsGnoyfw", title:"Event Highlights", tag1:"Video", tag2:"Vlog", mtitle:"MPN 2020" },
            { url:"https://www.youtube.com/watch?v=cqpu_p2Oy8w", title:"Event Highlights", tag1:"Video", tag2:"Vlog", mtitle:"BBQ Weekend" }
        ]

        const resourcesWebsite = [
            { url:"website/susun.png", title:"Web Application", tag1:"Web", tag2:"Application", mtitle: "susun"},
            { url:"website/taniculture.PNG", title:"Website", tag1:"Website", tag2:"Landing", mtitle: "taniculture"}
        ]

        return(
            <section id='portfolio' className="portfolio_fullwidth_area bg_color">
                <Sectitle sClass="hosting_title text-center" Title="Portfolio" TitleP="Have a look at our previous projects that we have done!"/>
                <div className="container-fluid pl-0 pr-0">
                    <div id="portfolio_filter" className="portfolio_filter mb_50">
                        <div data-filter="*" className={`work_portfolio_item ${this.onActive('*')}`} onClick={() => {this.onFilterChange("*")}}>All</div>
                        <div data-filter="website" className={`work_portfolio_item ${this.onActive('website')}`} onClick={() => {this.onFilterChange("website")}}>Website & Application</div>
                        <div data-filter="video" className={`work_portfolio_item ${this.onActive('video')}`} onClick={() => {this.onFilterChange("video")}}>Video Editing</div>
                        <div data-filter="live" className={`work_portfolio_item ${this.onActive('live')}`} onClick={() => {this.onFilterChange("live")}}>Live Broadcasting</div>
                        <div data-filter="graphic" className={`work_portfolio_item ${this.onActive('graphic')}`} onClick={() => {this.onFilterChange("graphic")}}>Graphic Design</div>
                    </div>
                    <div className="row portfolio_gallery ml-0 mr-0" id="work-portfolio">
                        {resourcesWebsite.map((resource2) => (
                            <Portfolio2griditem colClass="col-lg-3 mb-0 p0 col-sm-6 website" pImg={resource2.url} ptitle={resource2.title} tOne={resource2.tag1} tTwo={resource2.tag2}/>
                        ))}
                        {resourcesVideo.map((resource) => (
                            <Portfolio2gridvideo colClass="col-lg-3 mb-0 p0 col-sm-6 video" pUrl={resource.url} ptitle={resource.title} tOne={resource.tag1} tTwo={resource.tag2}/>
                        ))}
                    </div>
                </div>
            </section>  
        )
    }
}
export default Portfoliofull4col;
