import React, {Component} from 'react';
import Sectitle from '../Title/Sectitle';

class HostingPlan extends Component {
    constructor(){
        super();
        this.state= {
            Standard:[
                {
                    id: 1,
                    title: "Videography and editing prices can be varies from types of video, elements involved, storyboard, resources.",
                    priceList:[
                        {
                            "id": 1,
                            "Package":              "Event Video Recording",
                            "Recording":            "Yes",
                            "StoryboardDesign":     "No",
                            "Editing":              "No",
                            "MotionGraphic":        "No",
                            "Length":               "-",
                            "Price":                "RM120.00"
                        },
                        {
                            "id": 2,
                            "Package":              "Event Montage / Highlights",
                            "Recording":            "Yes",
                            "StoryboardDesign":     "Yes",
                            "Editing":              "Yes",
                            "MotionGraphic":        "Yes",
                            "Length":               "3m",
                            "Price":                "RM330.00"
                        },
                        {
                            "id": 3,
                            "Package":              "Product / Service Promotional Video",
                            "Recording":            "Yes",
                            "StoryboardDesign":     "Yes",
                            "Editing":              "Yes",
                            "MotionGraphic":        "Yes",
                            "Length":               "3m",
                            "Price":                "RM750.00"
                        },
                        {
                            "id": 4,
                            "Package":              "Corporate Promotional Video",
                            "Recording":            "Yes",
                            "StoryboardDesign":     "Yes",
                            "Editing":              "Yes",
                            "MotionGraphic":        "Yes",
                            "Length":               "5m",
                            "Price":                "RM1,500.00"
                        },
                        {
                            "id": 5,
                            "Package":              "Custom Video Editing",
                            "Recording":            "No",
                            "StoryboardDesign":     "Yes",
                            "Editing":              "Yes",
                            "MotionGraphic":        "Yes",
                            "Length":               "3m",
                            "Price":                "RM500.00"
                        },
                        {
                            "id": 6,
                            "Package":              "Custom Request",
                            "Recording":            "-",
                            "StoryboardDesign":     "-",
                            "Editing":              "-",
                            "MotionGraphic":        "-",
                            "Length":               "-",
                            "Price":                "Request for quote"
                        }
                    ]
                }
            ],
            Cpu:[
                {
                    id: 2,
                    title: "Oxford William owt to do with me Why cuppa buggered, nancy boy get stuffed mate absolutely bladdered blag matie boy the full monty bobby bleeder.",
                    priceList:[
                        {
                            "id": 1,
                            "Memory": "1 GB",
                            "Vcpus": "1 vCPU",
                            "Ssd": "125 GB",
                            "Transfer": "1 TB",
                            "Price": "$10/mo",
                            "PriceS": "$0.025/hr",
                        },
                        {
                            "id": 2,
                            "Memory": "2 GB",
                            "Vcpus": "2 vCPU",
                            "Ssd": "100 GB",
                            "Transfer": "2 TB",
                            "Price": "$20/mo",
                            "PriceS": "$0.025/hr",
                        },
                        {
                            "id": 3,
                            "Memory": "3 GB",
                            "Vcpus": "3 vCPU",
                            "Ssd": "50 GB",
                            "Transfer": "3 TB",
                            "Price": "$30/mo",
                            "PriceS": "$0.025/hr",
                        },
                        {
                            "id": 4,
                            "Memory": "4 GB",
                            "Vcpus": "4 vCPU",
                            "Ssd": "140 GB",
                            "Transfer": "4 TB",
                            "Price": "$40/mo",
                            "PriceS": "$0.015/hr",
                        },
                        {
                            "id": 5,
                            "Memory": "1 GB",
                            "Vcpus": "1 vCPU",
                            "Ssd": "125 GB",
                            "Transfer": "1 TB",
                            "Price": "$10/mo",
                            "PriceS": "$0.025/hr",
                        },
                        {
                            "id": 6,
                            "Memory": "2 GB",
                            "Vcpus": "2 vCPU",
                            "Ssd": "100 GB",
                            "Transfer": "2 TB",
                            "Price": "$20/mo",
                            "PriceS": "$0.025/hr",
                        },
                        {
                            "id": 7,
                            "Memory": "3 GB",
                            "Vcpus": "3 vCPU",
                            "Ssd": "50 GB",
                            "Transfer": "3 TB",
                            "Price": "$30/mo",
                            "PriceS": "$0.025/hr",
                        },
                        {
                            "id": 8,
                            "Memory": "4 GB",
                            "Vcpus": "4 vCPU",
                            "Ssd": "140 GB",
                            "Transfer": "4 TB",
                            "Price": "$40/mo",
                            "PriceS": "$0.015/hr",
                        }
                    ]
                }
            ],
            General:[
                {
                    id: 3,
                    title: "Oxford William owt to do with me Why cuppa buggered, nancy boy get stuffed mate absolutely bladdered blag matie boy the full monty bobby bleeder.",
                    priceList:[
                        {
                            "id": 1,
                            "Memory": "1 GB",
                            "Vcpus": "1 vCPU",
                            "Ssd": "125 GB",
                            "Transfer": "1 TB",
                            "Price": "$10/mo",
                            "PriceS": "$0.025/hr",
                        },
                        {
                            "id": 2,
                            "Memory": "2 GB",
                            "Vcpus": "2 vCPU",
                            "Ssd": "100 GB",
                            "Transfer": "2 TB",
                            "Price": "$20/mo",
                            "PriceS": "$0.025/hr",
                        },
                        {
                            "id": 3,
                            "Memory": "3 GB",
                            "Vcpus": "3 vCPU",
                            "Ssd": "50 GB",
                            "Transfer": "3 TB",
                            "Price": "$30/mo",
                            "PriceS": "$0.025/hr",
                        },
                        {
                            "id": 4,
                            "Memory": "4 GB",
                            "Vcpus": "4 vCPU",
                            "Ssd": "140 GB",
                            "Transfer": "4 TB",
                            "Price": "$40/mo",
                            "PriceS": "$0.015/hr",
                        },
                        {
                            "id": 5,
                            "Memory": "1 GB",
                            "Vcpus": "1 vCPU",
                            "Ssd": "125 GB",
                            "Transfer": "1 TB",
                            "Price": "$10/mo",
                            "PriceS": "$0.025/hr",
                        },
                        {
                            "id": 6,
                            "Memory": "2 GB",
                            "Vcpus": "2 vCPU",
                            "Ssd": "100 GB",
                            "Transfer": "2 TB",
                            "Price": "$20/mo",
                            "PriceS": "$0.025/hr",
                        },
                        {
                            "id": 7,
                            "Memory": "3 GB",
                            "Vcpus": "3 vCPU",
                            "Ssd": "50 GB",
                            "Transfer": "3 TB",
                            "Price": "$30/mo",
                            "PriceS": "$0.025/hr",
                        },
                        {
                            "id": 8,
                            "Memory": "4 GB",
                            "Vcpus": "4 vCPU",
                            "Ssd": "140 GB",
                            "Transfer": "4 TB",
                            "Price": "$40/mo",
                            "PriceS": "$0.015/hr",
                        }
                    ]
                }
            ]
        }
    }
    render(){
        return(
            <section id="pricing_overall" className="h_pricing_area sec_pad">
            <div className="container">
                <Sectitle Title="Check out awesome plans, And order now" TitleP="The full monty burke posh excuse my French Richard cheeky bobby spiffing crikey Why gormless, pear shaped.!" sClass="hosting_title text-center"/>
                <div className="h_price_inner">
                    <ul className="nav nav-tabs hosting_tab" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="videography-tab" data-toggle="tab" href="#videography" role="tab" aria-controls="videography" aria-selected="true">Videography</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="photography-tab" data-toggle="" href="#photography" role="tab" aria-controls="photography" aria-selected="false">Photography</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="website-tab" data-toggle="" href="#website" role="tab" aria-controls="website" aria-selected="false">Website / Application Development</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="live-tab" data-toggle="" href="#live" role="tab" aria-controls="live" aria-selected="false">Live Broadcasting</a>
                        </li>
                    </ul>
                    <div className="tab-content h_price_tab" id="myTabContent">
                        <div className="tab-pane fade show active" id="videography" role="tabpanel" aria-labelledby="videography-tab">
                            {this.state.Standard.map(post =>(
                                <React.Fragment key={post.id}>
                                    <p>{post.title}</p>
                                    <div className="h_price_body">
                                        <div className="price_head">
                                            <div className="p_head">
                                                <h5>Package</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Recording</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Storyboard</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Editing</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Motion Graphic</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Length</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Price</h5>
                                            </div>
                                        </div>
                                        <div className="h_price_body">
                                            {
                                                post.priceList.map( list => {
                                                    return(
                                                        <div className="h_p_list" key={list.id}>
                                                            <div className="h_price_item memory" data-title="Package">
                                                                <h5>{list.Package}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Recording">
                                                                <h5>{list.Recording}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Editing">
                                                                <h5>{list.Editing}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Storyboard">
                                                                <h5>{list.StoryboardDesign}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Motion Graphic">
                                                                <h5>{list.MotionGraphic}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Length">
                                                                <h5>{list.Length}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Price">
                                                                <h5>{list.Price}</h5>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div> 
                                </React.Fragment>          
                            ))}
                        </div>
                        <div className="tab-pane fade" id="photography" role="tabpanel" aria-labelledby="photography-tab">
                            {this.state.Cpu.map(post =>(
                                <React.Fragment key={post.id}>
                                    <p>{post.title}</p>
                                    <div className="h_price_body">
                                        <div className="price_head">
                                            <div className="p_head">
                                                <h5>Memory</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Vcpus</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Ssd disk</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Transfer</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Price</h5>
                                            </div>
                                            <div className="p_head c_width">
                                                    
                                            </div>
                                        </div>
                                        <div className="h_price_body">
                                            {
                                                post.priceList.map( list => {
                                                    return(
                                                        <div className="h_p_list" key={list.id}>
                                                            <div className="h_price_item memory" data-title="Memory">
                                                                <h5>{list.Memory}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Vcpus">
                                                                <h5>{list.Vcpus}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Ssd disk">
                                                                <h5>{list.Ssd}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Transfer">
                                                                <h5>{list.Transfer}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Price">
                                                                <h5><span>{list.Price}</span>{list.PriceS}</h5>
                                                            </div>
                                                            <div className="h_price_item c_width">
                                                                <h5><a href="/#" className="h_price_btn">Choose Plan</a></h5>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div> 
                                </React.Fragment>          
                            ))}
                        </div>
                        <div className="tab-pane fade" id="website" role="tabpanel" aria-labelledby="website-tab">
                            {this.state.General.map(post =>(
                                <React.Fragment key={post.id}>
                                    <p>{post.title}</p>
                                    <div className="h_price_body">
                                        <div className="price_head">
                                            <div className="p_head">
                                                <h5>Memory</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Vcpus</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Ssd disk</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Transfer</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Price</h5>
                                            </div>
                                            <div className="p_head c_width">
                                                    
                                            </div>
                                        </div>
                                        <div className="h_price_body">
                                            {
                                                post.priceList.map( list => {
                                                    return(
                                                        <div className="h_p_list" key={list.id}>
                                                            <div className="h_price_item memory" data-title="Memory">
                                                                <h5>{list.Memory}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Vcpus">
                                                                <h5>{list.Vcpus}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Ssd disk">
                                                                <h5>{list.Ssd}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Transfer">
                                                                <h5>{list.Transfer}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Price">
                                                                <h5><span>{list.Price}</span>{list.PriceS}</h5>
                                                            </div>
                                                            <div className="h_price_item c_width">
                                                                <h5><a href="/#" className="h_price_btn">Choose Plan</a></h5>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div> 
                                </React.Fragment>          
                            ))}
                        </div>
                        <div className="tab-pane fade" id="live" role="tabpanel" aria-labelledby="live-tab">
                            {this.state.General.map(post =>(
                                <React.Fragment key={post.id}>
                                    <p>{post.title}</p>
                                    <div className="h_price_body">
                                        <div className="price_head">
                                            <div className="p_head">
                                                <h5>Memory</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Vcpus</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Ssd disk</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Transfer</h5>
                                            </div>
                                            <div className="p_head">
                                                <h5>Price</h5>
                                            </div>
                                            <div className="p_head c_width">
                                                    
                                            </div>
                                        </div>
                                        <div className="h_price_body">
                                            {
                                                post.priceList.map( list => {
                                                    return(
                                                        <div className="h_p_list" key={list.id}>
                                                            <div className="h_price_item memory" data-title="Memory">
                                                                <h5>{list.Memory}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Vcpus">
                                                                <h5>{list.Vcpus}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Ssd disk">
                                                                <h5>{list.Ssd}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Transfer">
                                                                <h5>{list.Transfer}</h5>
                                                            </div>
                                                            <div className="h_price_item" data-title="Price">
                                                                <h5><span>{list.Price}</span>{list.PriceS}</h5>
                                                            </div>
                                                            <div className="h_price_item c_width">
                                                                <h5><a href="/#" className="h_price_btn">Choose Plan</a></h5>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div> 
                                </React.Fragment>          
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        )
    }
}
export default HostingPlan;