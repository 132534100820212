import React, {Component} from 'react';

class Partner extends Component {
    render(){
        var {pClass,pClasst} = this.props;
        return(
            <section className={`${pClass}`}>
                <div className="container">
                    <div className={`partner_logo_area_four ${pClasst}`}>
                        <h4 className="f_size_18 f_400 f_p text-center l_height28 mb_70">Our Clients</h4>
                        <div className="row partner_info">
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (6).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (14).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (25).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (15).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (3).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (18).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (21).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (23).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (11).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (7).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (19).svg')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (20).svg')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (22).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (24).svg')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (5).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (17).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (9).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (1).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (10).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (12).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (13).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (2).png')} alt=""/></a>
                            </div>
                            <div className="logo_item wow fadeInLeft" data-wow-delay="1.0s">
                                <a href=".#"><img src={require ('../img/clients/client (16).png')} alt=""/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Partner;