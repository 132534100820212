import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Sticky from 'react-stickynode';
import { HashLink as LinkH } from 'react-router-hash-link';

class CustomNavbar extends Component {
    render() {
        var {mClass, nClass, cClass, slogo, hbtnClass} =this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img src={require("../img/nazethics_logo_whte_xs.png")} alt=""/>
                            <img src={require("../img/nazethics_logo_xs.png")} alt="logo"/>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                <li className="nav-item"><NavLink title="Home" className="nav-link" to="/">Home</NavLink></li>
                                <li className="nav-item"><LinkH title="Portfolio Fullwidth" className="nav-link" to='/#portfolio'>Portfolio</LinkH></li>
                                <li className="nav-item"><LinkH title="Pricing" className="nav-link" to="/#pricing_overall">Pricing</LinkH></li>
                                <li className="nav-item"><LinkH title="Contact" className="nav-link" to="/#contact">Contact</LinkH></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;