import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import HostingBanner from '../components/Banner/HostingBanner';
import HostingService from '../components/Service/HostingService';
import HostingFeatures from '../components/Service/HostingFeatures';
import HostingFeaturesTwo from '../components/Service/HostingFeaturesTwo';
import HostingAction from '../components/HostingAction';
import HostingPlan from '../components/Features/HostingPlan';
import HostingActionTwo from '../components/HostingActionTwo';
import FooterErp from '../components/Footer/FooterErp';
import FooterData from '../components/Footer/FooterData';
import Portfoliofull from '../components/Portfolios/Portfoliofull4col';
import Sectitle from '../components/Title/Sectitle';
import Partner from '../components/Partner';

const HomeHosting =()=> {
    return(
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu" slogo="sticky_logo"/>
            <HostingBanner/>
            <Portfoliofull/>
            <HostingService/>
            <HostingFeatures/>
            <HostingFeaturesTwo/>
            <Sectitle sClass="hosting_title text-center" Title="Equipment Supply" TitleP="We also provide equipment supply for your project or in doing research and development."/>
            <HostingAction aClass="h_action_area" aimage="action_img.png" colClass="col-lg-6" colClassTwo="col-lg-6" Atitle="Blockchain" Atext="We can develop smart contracts (solidity) and decentralize applications (DApps) for your project as well. Feel free to contact and discuss with us."/>
            <HostingPlan/>
            <Partner/>
            <HostingActionTwo/>
            <FooterErp FooterData={FooterData}/>
        </div>
    )
}
export default HomeHosting;