const FooterData = {
    CompanyWidget: [
        {
            id: 1,
            title: "Get In Touch",
            description: "Don’t miss any updates of our new templates and extensions.!",
        }
    ],
    AboutWidget: [
        {
          id: 1,
          title: "Services",
          menuItems: [
              {
                id: 1,
                url: '#!',
                text: 'Videography',
              },
              {
                id: 2,
                url: '#!',
                text: 'Photography',
              },
              {
                id: 3,
                url: '#!',
                text: 'Website Development',
              },
              {
                id: 4,
                url: '#!',
                text: 'Application Development',
              },
              {
                id: 5,
                url: '#!',
                text: 'Live Broadcasting',
              },
              {
                  id: 6,
                  url: '#!',
                  text: 'Equipment Supply',
              },
          ],
      },
      {
            id: 2,
            title: "Contact Us",
            menuItems: [
                {
                  id: 1,
                  url: 'https://wa.me/60139136796',
                  text: 'WhatsApp',
                },
                {
                  id: 2,
                  url: 'mailto:enquiry@nazethics.com',
                  text: 'Email',
                }
            ],
        },
    ],
    termsCondition: [
      {
          id: 1,
          title: "",
          menuItems: [
            {
              id: 1,
              url: '#',
              text: '',
            },
            {
              id: 2,
              url: '#',
              text: '',
            },
            {
              id: 3,
              url: '#',
              text: '',
            },
            {
              id: 4,
              url: '#',
              text: '',
            }
        ],
      }
    ],
    SocialLinks: [
        {
            id: 1,
            title: "Team Solutions",
            menuItems: [
                {
                  id: 1,
                  url: '#',
                  icon: 'ti-facebook',
                },
                {
                  id: 2,
                  url: '#',
                  icon: 'ti-twitter-alt',
                },
                {
                  id: 3,
                  url: '#',
                  icon: 'ti-vimeo-alt',
                },
                {
                  id: 4,
                  url: '#',
                  icon: 'ti-pinterest',
                }
            ]
        }
    ],
    socialIcon:[
    ],
    copywrite:"© Nazethics Solution 2019 All rights reserved.",
    TestimonialText: [
      {
        id: 1,
        image: "new/member_01.jpg",
        description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
        authorName: "Phillip Anthropy",
        authorPost: "UI/UX designer", 
      },
      {
        id: 2,
        image: "new/member_01.jpg",
        description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
        authorName: "Phillip Anthropy",
        authorPost: "UI/UX designer", 
      },
      {
        id: 3,
        image: "new/member_01.jpg",
        description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
        authorName: "Phillip Anthropy",
        authorPost: "UI/UX designer", 
      },
      {
        id: 4,
        image: "new/member_01.jpg",
        description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
        authorName: "Phillip Anthropy",
        authorPost: "UI/UX designer", 
      }
    ],
    aboutLinks: [
      {
        id: 1,
        url: '#',
        item: 'Developer',
      },
      {
        id: 2,
        url: '#',
        item: 'Blog',
      },
      {
        id: 3,
        url: '#',
        item: 'Investor',
      },
      {
        id: 4,
        url: '#',
        item: 'Sitemap',
      },
      {
        id: 5,
        url: '#',
        item: 'Jobs',
      }
    ],
    helpLinks: [
      {
        id: 1,
        url: '#',
        item: 'Help and Contact',
      },
      {
        id: 2,
        url: '#',
        item: 'Fees',
      },
      {
        id: 3,
        url: '#',
        item: 'Security',
      },
      {
        id: 4,
        url: '#',
        item: 'App',
      },
      {
        id: 5,
        url: '#',
        item: 'Shop',
      }
    ],
    PrivacyLinks: [
      {
        id: 1,
        url: '#',
        item: 'Privacy Policy',
      },
      {
        id: 2,
        url: '#',
        item: 'Legal Agreement',
      },
      {
        id: 3,
        url: '#',
        item: 'Feedback',
      }
    ],
    about: [
      {
        id: 1,
        url: '#',
        text: 'Company',
      },
      {
        id: 2,
        url: '#',
        text: 'Leadership',
      },
      {
        id: 3,
        url: '#',
        text: 'Diversity',
      },
      {
        id: 4,
        url: '#',
        text: 'Jobs',
      },
      {
        id: 5,
        url: '#',
        text: 'Press',
      },
      {
        id: 6,
        url: '#',
        text: 'Wavelength',
      },
    ],
    Solution: [
      {
        id: 1,
        url: '#',
        text: 'Project Management',
      },
      {
        id: 2,
        url: '#',
        text: 'Agile',
      },
      {
        id: 3,
        url: '#',
        text: 'Task Management',
      },
      {
        id: 4,
        url: '#',
        text: 'Reporting',
      },
      {
        id: 5,
        url: '#',
        text: 'Work Tracking',
      },
      {
        id: 6,
        url: '#',
        text: 'See All Uses',
      },
    ],
    teamSolution: [
      {
        id: 1,
        url: '#',
        text: 'Engineering',
      },
      {
        id: 2,
        url: '#',
        text: 'Designers',
      },
      {
        id: 3,
        url: '#',
        text: 'Sales',
      },
      {
        id: 4,
        url: '#',
        text: 'Developers',
      },
      {
        id: 5,
        url: '#',
        text: 'Marketing',
      },
      {
        id: 6,
        url: '#',
        text: 'See All team',
      },
    ],
};
export default FooterData;