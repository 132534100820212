import React from 'react';
import Sectitle from '../Title/Sectitle';
import HRServiceItems from './HostingServiceitem';

const HostingService =()=> {
    return(
        <section className="hosting_service_area sec_pad">
            <div className="container">
                <Sectitle sClass="hosting_title text-center" Title="About Nazethics Solution" TitleP="Nazethics Solution is an enterprise business from Malaysia founded in 2019. We mainly focus on media and digital services. We are registered under Companies Commission of Malaysia (SSM) with certificate number 003038744-M."/>
                <div className="row">
                    <HRServiceItems hostingicon="icon2.png" hostingserviceTitle="Services" hostingservicedescription="We provide services in - Web Development, Application Development (Web & Mobile), Video Editing, Graphic Designing, Live Broadcasting, Equipment Supply, Consultation."/>
                    <HRServiceItems hostingicon="icon8.png" hostingserviceTitle="International Partnership" hostingservicedescription="We partnered with company from Yemen, STDGroup to provide the best web development service."/>
                </div>
            </div>
        </section>
    )
}
export default HostingService;