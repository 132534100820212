import React, {Component, useState} from 'react';
import Portfolio2gridvideohover from './Portfolio2gridvideohover';

class Portfolio2griditem extends Component{

    render(){
        var {pUrl, ptitle, tOne, tTwo, colClass} = this.props;
        return(
            <div className={`portfolio_item mb-30 ${colClass} `}>
                <div className="portfolio_img">    
                    <Portfolio2gridvideohover pUrl={pUrl} ptitle={ptitle} tOne={tOne} tTwo={tTwo} />
                </div>
            </div>
            
        )
    }
}
export default Portfolio2griditem;