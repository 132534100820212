import React from 'react';
import HostingFeaturesItem from './HostingFeaturesItem';

const HostingFeatures =()=>{
    return(
        <section className="hosting_features_area">
            <div className="container">
                <HostingFeaturesItem hImage="business1.png" hTitle="Web & Application Development" hPragraph="Looking for your own custom website, web application or mobile application? You've come to the right place." 
                icon="icon10.png" icon2="icon9.png" text="Custom Website" text2="Web / Mobile Application" tdescription="Small Business Professional Website, Landing Page, Corporate Website, Wordpress, Joomla" tdescription2="Business Management System, Shop Loyalty Management System, Food Ordering & POS System"/>
            </div>
        </section>
    )
}

export default HostingFeatures;