import React, { Component, useState } from 'react'
import ReactPlayer from 'react-player';

const Portfolio2gridvideohover = ({pUrl, ptitle, tOne, tTwo}) => {
  const [isPlaying, setPlayState] = useState ();

  return(
    <>
      <div className="player-wrapper">
          <ReactPlayer className="react-player" 
          url={pUrl} 
          controls
          playing={isPlaying}
          onPause={() => setPlayState(false)}
          width='100%'
          height='100%'
          />
      </div>
      {!isPlaying && (
        <div className="hover_content" onClick={() => setPlayState(true)}>
        <div className="portfolio-description leaf">
            <div className="portfolio-title">
                <h3 className="f_500 f_size_20 f_p">{ptitle}</h3>
            </div>
            <div className="links">
                <a href=".#">{tOne}</a>
                <a href=".#">{tTwo}</a>
            </div>
        </div>
      </div>
      )}
    </>
  )
}

export default Portfolio2gridvideohover;